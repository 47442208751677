<template>
  <div style="align-items: center">
    <div>
      <div class="main_table">
        <div class="header">
          <div>
            <SelectLocale />
          </div>
        </div>
        <div
          style="
            text-align: center;
            margin-top: 47px;
            font-weight: bold;
            font-size: 22px;
          "
        >
          {{ $t("naslov") }}
        </div>
        <div
          style="
            text-align: center;
            margin-top: 15px;
            margin-bottom: 27px;
            font-size: 17px;
          "
        >
          {{ $t("podnaslov") }}
        </div>
        <div class="nesto">
          <div class="dropdown">
            <div class="input_element">
              <input
                v-model="selected_1"
                type="checkbox"
                name="selected_1"
                @click="clean_selected_1"
              />
              <label for="selected_1">{{ $t("rezident1") }}</label>
            </div>
            <table
              v-if="selected_1"
              :style="{
                'margin-top': screen
                  ? '10px'
                  : $i18n.locale === 'sr'
                  ? '30px'
                  : $i18n.locale === 'en'
                  ? '11px'
                  : '11px',
              }"
            >
              <tr>
                <td>
                  <input
                    type="text"
                    v-model.number="bruto_1"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                    v-on:keyup.enter="calculate"
                  />
                </td>
              </tr>
              <tr>
                <td style="padding-top: 0px; font-size: 15px">
                  {{ $t("unesi_prihod") }}
                </td>
              </tr>
              <tr v-if="selected_1 && poreski_kredit">
                <td>
                  <input
                    type="text"
                    v-model.number="prihod_1"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                    v-on:keyup.enter="calculate"
                  />
                </td>
              </tr>
              <tr v-if="selected_1 && poreski_kredit">
                <td style="padding-top: 0px; font-size: 15px">
                  (Upišite prihod na koji je plaćen porez u drugoj zemlji)
                </td>
              </tr>
              <tr v-if="selected_1 && poreski_kredit">
                <td>
                  <input
                    type="text"
                    v-model.number="porez_1"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                    v-on:keyup.enter="calculate"
                  />
                </td>
              </tr>
              <tr v-if="selected_1 && poreski_kredit">
                <td style="padding-top: 0px; font-size: 15px">
                  (Upišite iznos poreza koji je plaćen u drugoj zemlji)
                </td>
              </tr>
            </table>
          </div>
          <div class="dropdown">
            <div class="input_element">
              <input
                v-model="selected_2"
                type="checkbox"
                name="selected_2"
                @click="clean_selected_2"
              />
              <label for="selected_2">{{ $t("rezident2") }}</label>
            </div>
            <table
              v-if="selected_2"
              :style="{
                'margin-top': screen
                  ? '10px'
                  : $i18n.locale === 'sr'
                  ? '10px'
                  : $i18n.locale === 'en'
                  ? '30px'
                  : '11px',
              }"
            >
              <tr>
                <td>
                  <input
                    type="text"
                    v-model.number="bruto_2"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                    v-on:keyup.enter="calculate"
                  />
                </td>
              </tr>
              <tr>
                <td style="padding-top: 0px; font-size: 15px">
                  {{ $t("unesi_prihod") }}
                </td>
              </tr>
              <tr v-if="selected_2 && poreski_kredit">
                <td>
                  <input
                    type="text"
                    v-model.number="prihod_2"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                  />
                </td>
              </tr>
              <tr v-if="selected_2 && poreski_kredit">
                <td style="padding-top: 0px; font-size: 15px">
                  (Upišite prihod na koji je plaćen porez u drugoj zemlji)
                </td>
              </tr>
              <tr v-if="selected_2 && poreski_kredit">
                <td>
                  <input
                    type="text"
                    v-model.number="porez_2"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                  />
                </td>
              </tr>
              <tr v-if="selected_2 && poreski_kredit">
                <td style="padding-top: 0px; font-size: 15px">
                  (Upišite iznos poreza koji je plaćen u drugoj zemlji)
                </td>
              </tr>
            </table>
          </div>
          <div class="dropdown">
            <div class="input_element">
              <input
                v-model="selected_3"
                type="checkbox"
                name="selected_3"
                @click="clean_selected_3"
              />
              <label for="selected_3">{{ $t("rezident3") }}</label>
            </div>
            <table
              v-if="selected_3"
              :style="{
                'margin-top': screen
                  ? '10px'
                  : $i18n.locale === 'sr'
                  ? '10px'
                  : $i18n.locale === 'en'
                  ? '29px'
                  : '11px',
              }"
            >
              <tr>
                <td>
                  <input
                    type="text"
                    v-model.number="bruto_3"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                    v-on:keyup.enter="calculate"
                  />
                </td>
              </tr>
              <tr>
                <td style="padding-top: 0px; font-size: 15px">
                  {{ $t("unesi_prihod") }}
                </td>
              </tr>
              <tr v-if="selected_3 && poreski_kredit">
                <td>
                  <input
                    type="text"
                    v-model.number="prihod_3"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                  />
                </td>
              </tr>
              <tr v-if="selected_3 && poreski_kredit">
                <td style="padding-top: 0px; font-size: 15px">
                  (Upišite prihod na koji je plaćen porez u drugoj zemlji)
                </td>
              </tr>
              <tr v-if="selected_3 && poreski_kredit">
                <td>
                  <input
                    type="text"
                    v-model.number="porez_3"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                  />
                </td>
              </tr>
              <tr v-if="selected_3 && poreski_kredit">
                <td style="padding-top: 0px; font-size: 15px">
                  (Upišite iznos poreza koji je plaćen u drugoj zemlji)
                </td>
              </tr>
            </table>
          </div>
          <div class="dropdown">
            <div class="input_element">
              <input
                v-model="selected_4"
                type="checkbox"
                name="selected_4"
                @click="clean_selected_4"
              />
              <label for="selected_4">{{ $t("rezident4") }}</label>
            </div>
            <table
              v-if="selected_4"
              :style="{ 'margin-top': screen ? '10px' : '69px' }"
            >
              <tr>
                <td>
                  <input
                    type="text"
                    v-model.number="bruto_4"
                    @input="set_to_zero"
                    min="0"
                    class="input"
                    v-on:keyup.enter="calculate"
                  />
                </td>
              </tr>
              <tr>
                <td style="padding-top: 0px; font-size: 15px">
                  {{ $t("unesi_prihod") }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="error"
          v-if="(bruto_1 > 0 || bruto_2 > 0 || bruto_3 > 0) && bruto_4 > 0"
        >
          {{ $t("error1") }}
        </div>
        <div
          class="error"
          v-if="prihod_1 > bruto_1 || prihod_2 > bruto_2 || prihod_3 > bruto_3"
        >
          {{ $t("error2") }}
        </div>
        <div
          class="error"
          v-if="
            porez_1 > bruto_1 ||
            porez_1 > prihod_1 ||
            porez_2 > bruto_2 ||
            porez_2 > prihod_2 ||
            porez_3 > bruto_3 ||
            porez_3 > prihod_3
          "
        >
          {{ $t("error3") }}
        </div>
        <div class="osiguranje">
          <p
            style="
              padding-top: 1px;
              margin-bottom: 0px;
              font-size: 22px;
              font-weight: bold;
            "
          >
            {{ $t("osigurani") }}
          </p>
          <div class="wrapper2">
            <input
              class="inline-button"
              type="radio"
              id="da"
              v-bind:value="true"
              v-model="osiguranje"
            /><label for="da" style="padding-top: 3px; margin-right: 10px">{{
              $t("da")
            }}</label>
            <input
              class="inline-button"
              type="radio"
              id="ne"
              v-bind:value="false"
              v-model="osiguranje"
            /><label for="ne" style="padding-top: 3px">{{ $t("ne") }}</label>
          </div>
        </div>
        <div
          style="
            text-align: center;
            margin-top: 30px;
            align-items: center;
            justify-content: center;
          "
        >
          <p style="font-weight: bold; font-size: 22px">
            {{ $t("ukupno_u_kvartalu") }}
          </p>
          <p style="font-size: 19px; font-weight: bold">
            {{ format(bruto) }} RSD
          </p>
          <button @click="calculate()" class="potvrdi">
            {{ $t("potvrdi") }}
          </button>
        </div>

        <div class="wraper">
          <ModelFirst ref="modelFirst" />
          <ModelSecond ref="modelSecond" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModelFirst from "./components/ModelFirst.vue";
import ModelSecond from "./components/ModelSecond.vue";
import i18n from "./locales/i18n";
import SelectLocale from "./locales/SelectLocale.vue";

export default {
  name: "App",
  data() {
    return {
      selected_1: false,
      selected_2: false,
      selected_3: false,
      selected_4: false,
      osiguranje: false,
      poreski_kredit: false,
      opened: false,
      title: "",
      input: 1000,
      bruto: 0,
      bruto_1: 0,
      bruto_2: 0,
      bruto_3: 0,
      bruto_4: 0,
      prihod_1: 0,
      prihod_2: 0,
      prihod_3: 0,
      porez_1: 0,
      porez_2: 0,
      porez_3: 0,
      model_1_1: 0,
      model_1_2: 0,
      model_1_3: 0,
      model_2_1: 0,
      model_2_2: 0,
      model_2_3: 0,
      stat_norm_trosak: 103296,
      stat_norm_trosak2: 62300,
      porez: 0.2,
      porez2: 0.1,
      normirani_trosak: 0,
      normirani_trosak2: 0.34,
      screen: false,
    };
  },
  components: {
    ModelFirst,
    ModelSecond,
    i18n,
    SelectLocale,
  },
  created() {
    // Provera veličine ekrana prilikom kreiranja komponente
    this.checkScreenSize();

    // Slušanje promena veličine ekrana
    window.addEventListener("resize", this.checkScreenSize);
  },
  watch: {
    osiguranje: function (newosiguranje, oldosiguranje) {
      console.log("Vrijednost brojača se promijenila!");
      this.calculate();
    },
  },
  methods: {
    checkScreenSize() {
      // Dobijanje širine ekrana
      const screenWidth = window.innerWidth;
      if (screenWidth < 820) {
        this.screen = true;
      } else {
        this.screen = false;
      }
    },
    toggle() {
      this.$refs.modelFirst.change(this.osiguranje);
      this.$refs.modelSecond.change(this.osiguranje);
    },
    clean() {
      this.prihod_1 = 0;
      this.prihod_2 = 0;
      this.prihod_3 = 0;
      this.porez_1 = 0;
      this.porez_2 = 0;
      this.porez_3 = 0;
      this.calculate();
    },
    clean_selected_1() {
      this.bruto_1 = 0;
      this.prihod_1 = 0;
      this.porez_1 = 0;
    },
    clean_selected_2() {
      this.bruto_2 = 0;
      this.prihod_2 = 0;
      this.porez_2 = 0;
    },
    clean_selected_3() {
      this.bruto_3 = 0;
      this.prihod_3 = 0;
      this.porez_3 = 0;
    },
    clean_selected_4() {
      this.bruto_4 = 0;
    },
    format(num) {
      num = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num);
      return num;
    },
    set_to_zero() {
      if (this.bruto_1 == "") {
        this.bruto_1 = 0;
      }
      if (this.bruto_2 == "") {
        this.bruto_2 = 0;
      }
      if (this.bruto_3 == "") {
        this.bruto_3 = 0;
      }
      if (this.bruto_4 == "") {
        this.bruto_4 = 0;
      }

      if (this.prihod_1 == "") {
        this.prihod_1 = 0;
      }
      if (this.prihod_2 == "") {
        this.prihod_2 = 0;
      }
      if (this.prihod_3 == "") {
        this.prihod_3 = 0;
      }

      if (this.porez_1 == "") {
        this.porez_1 = 0;
      }
      if (this.porez_2 == "") {
        this.porez_2 = 0;
      }
      if (this.porez_3 == "") {
        this.porez_3 = 0;
      }
    },
    calculate() {
      if (
        (this.bruto_1 > 0 || this.bruto_2 > 0 || this.bruto_3 > 0) &&
        this.bruto_4 > 0
      ) {
        return;
      }
      if (
        this.prihod_1 > this.bruto_1 ||
        this.prihod_2 > this.bruto_2 ||
        this.prihod_3 > this.bruto_3
      ) {
        return;
      }
      if (
        this.porez_1 > this.bruto_1 ||
        this.porez_1 > this.prihod_1 ||
        this.porez_2 > this.bruto_2 ||
        this.porez_2 > this.prihod_2 ||
        this.porez_3 > this.bruto_3 ||
        this.porez_3 > this.prihod_3
      ) {
        return;
      }
      this.$refs.modelFirst.change(this.osiguranje);
      this.$refs.modelSecond.change(this.osiguranje);
      // model_1_1
      if (
        (this.prihod_1 -
          this.stat_norm_trosak -
          this.prihod_1 * this.normirani_trosak) *
          this.porez <=
        0
      ) {
        this.model_1_1 = 0;
      } else if (this.porez_1 <= 0) {
        this.model_1_1 = 0;
      } else if (
        (this.prihod_1 -
          this.stat_norm_trosak -
          this.prihod_1 * this.normirani_trosak) *
          this.porez >
        this.porez_1
      ) {
        this.model_1_1 = this.porez_1.toFixed(2);
      } else {
        this.model_1_1 = (
          (this.prihod_1 -
            this.stat_norm_trosak -
            this.prihod_1 * this.normirani_trosak) *
          this.porez
        ).toFixed(2);
      }
      // model_1_2
      if (
        (this.prihod_2 -
          this.stat_norm_trosak -
          this.prihod_2 * this.normirani_trosak) *
          this.porez <=
        0
      ) {
        this.model_1_2 = 0;
      } else if (this.porez_2 <= 0) {
        this.model_1_2 = 0;
      } else if (
        (this.prihod_2 -
          this.stat_norm_trosak -
          this.prihod_2 * this.normirani_trosak) *
          this.porez >
        this.porez_2
      ) {
        this.model_1_2 = this.porez_2.toFixed(2);
      } else {
        this.model_1_2 = (
          (this.prihod_2 -
            this.stat_norm_trosak -
            this.prihod_2 * this.normirani_trosak) *
          this.porez
        ).toFixed(2);
      }
      // model_1_3
      if (
        (this.prihod_3 -
          this.stat_norm_trosak -
          this.prihod_3 * this.normirani_trosak) *
          this.porez <=
        0
      ) {
        this.model_1_3 = 0;
      } else if (this.porez_3 <= 0) {
        this.model_1_3 = 0;
      } else if (
        (this.prihod_3 -
          this.stat_norm_trosak -
          this.prihod_3 * this.normirani_trosak) *
          this.porez >
        this.porez_3
      ) {
        this.model_1_3 = this.porez_3.toFixed(2);
      } else {
        this.model_1_3 = (
          (this.prihod_3 -
            this.stat_norm_trosak -
            this.prihod_3 * this.normirani_trosak) *
          this.porez
        ).toFixed(2);
      }
      //model_2_1
      if (
        (this.prihod_1 -
          this.stat_norm_trosak2 -
          this.prihod_1 * this.normirani_trosak2) *
          this.porez2 <=
        0
      ) {
        this.model_2_1 = 0;
      } else if (this.porez_1 <= 0) {
        this.model_2_1 = 0;
      } else if (
        (this.prihod_1 -
          this.stat_norm_trosak2 -
          this.prihod_1 * this.normirani_trosak2) *
          this.porez2 >
        this.porez_1
      ) {
        this.model_2_1 = this.porez_1.toFixed(2);
      } else {
        this.model_2_1 = Math.round(
          (this.prihod_1 -
            this.stat_norm_trosak2 -
            this.prihod_1 * this.normirani_trosak2) *
            this.porez2
        ).toFixed(2);
      }
      // model_2_2
      if (
        (this.prihod_2 -
          this.stat_norm_trosak2 -
          this.prihod_2 * this.normirani_trosak2) *
          this.porez2 <=
        0
      ) {
        this.model_2_2 = 0;
      } else if (this.porez_2 <= 0) {
        this.model_2_2 = 0;
      } else if (
        (this.prihod_2 -
          this.stat_norm_trosak2 -
          this.prihod_2 * this.normirani_trosak2) *
          this.porez2 >
        this.porez_2
      ) {
        this.model_2_2 = this.porez_2.toFixed(2);
      } else {
        this.model_2_2 = Math.round(
          (this.prihod_2 -
            this.stat_norm_trosak2 -
            this.prihod_2 * this.normirani_trosak2) *
            this.porez2
        ).toFixed(2);
      }
      // model_2_3
      if (
        (this.prihod_3 -
          this.stat_norm_trosak2 -
          this.prihod_3 * this.normirani_trosak2) *
          this.porez2 <=
        0
      ) {
        this.model_2_3 = 0;
      } else if (this.porez_3 <= 0) {
        this.model_2_3 = 0;
      } else if (
        (this.prihod_3 -
          this.stat_norm_trosak2 -
          this.prihod_3 * this.normirani_trosak2) *
          this.porez2 >
        this.porez_3
      ) {
        this.model_2_3 = this.porez_3.toFixed(2);
      } else {
        this.model_2_3 = Math.round(
          (this.prihod_3 -
            this.stat_norm_trosak2 -
            this.prihod_3 * this.normirani_trosak2) *
            this.porez2
        ).toFixed(2);
      }
      this.bruto = (
        this.bruto_1 +
        this.bruto_2 +
        this.bruto_3 +
        this.bruto_4
      ).toFixed(2);
      let model1_sum =
        parseFloat(this.model_1_1) +
        parseFloat(this.model_1_2) +
        parseFloat(this.model_1_3);
      let model2_sum =
        parseFloat(this.model_2_1) +
        parseFloat(this.model_2_2) +
        parseFloat(this.model_2_3);
      this.$refs.modelFirst.calculateModel1(
        this.bruto_1,
        this.bruto_4,
        this.bruto_3,
        this.bruto,
        model1_sum,
        this.bruto_2
      );
      this.$refs.modelSecond.calculateModel1(
        this.bruto_1,
        this.bruto_4,
        this.bruto_3,
        this.bruto,
        model2_sum,
        this.bruto_2
      );
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 30px;
}
.wraper {
  display: flex;
  justify-content: space-around;
  margin: auto;
  margin-top: 50px;
}
@media screen and (max-width: 820px) {
  .wraper {
    display: flex;
    justify-content: space-around;
    margin: auto;
    margin: 10px;
    flex-direction: column;
  }
}
.main_table {
  width: auto;
  margin-left: 50px;
  margin-right: 50px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: center;
  padding: 8px;
}

.error {
  color: red;
  text-align: center;
}
.potvrdi {
  width: 140px;
  height: 45px;
  background-color: #25a8ad;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 22px;
}
.potvrdi:hover {
  width: 140px;
  height: 45px;
  background-color: #6aaac5;
  color: black;
  border: none;
  border-radius: 25px;
  font-size: 22px;
}
.potvrdi:active {
  width: 140px;
  height: 45px;
  background-color: #25a8ad;
  color: black;
  border-radius: 25px;
  font-size: 22px;
}
.input {
  text-align: right;
}
.osiguranje {
  text-align: center;
}
@media screen and (max-width: 820px) {
  .osiguranje {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: 0px;
  }
}
.header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-end;
  align-content: stretch;
  align-items: center;
  font-size: 17px;
}
.input_element {
  width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 250px;
  text-align: center;
}
@media screen and (max-width: 820px) {
  .dropdown {
    margin-top: 10px;
  }
}
.nesto {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  font-size: 17px;
}
@media screen and (max-width: 820px) {
  .nesto {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 17px;
  }
}
input {
  border: solid;
  border-color: #25a8ad;
  height: 26px;
  font-size: 14px;
  border-radius: 6px;
}
.checkbox {
  border-color: #25a8ad;
  background-color: #25a8ad;
}
input[type="checkbox"] {
  accent-color: #25a8ad;
  width: 15px;
}
.toggle_btn {
  background-color: red;
}
.active {
  background-color: green;
}
.small {
  padding-top: 0px;
  font-size: 15px;
}
.wrapper2 {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 11px;
  font-size: 19px;
}
.wrapper3 {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.inline-button {
  display: inline-flex;
}
input[type="radio"] {
  accent-color: #25a8ad;
}
.logo {
  text-align: left;
  height: 100px;
}
@media screen and (max-width: 820px) {
  .logo {
    text-align: center;
    height: 100px;
  }
}
.footer {
  text-align: center;
  margin-top: 40px;
}
</style>
