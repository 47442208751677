<template>
  <div>
    <div class="model_1">Model 1</div>
    <table>
      <tr>
        <th colspan="1" style="text-align: center"></th>
      </tr>
      <tr style="font-size: 17px">
        <td>{{ $t("normirani_trosak") }}</td>
        <td style="width: 100px">{{ normirani_trosak }}%</td>
        <td></td>
      </tr>
      <tr style="font-size: 17px; border-bottom: solid; border-color: #746c98">
        <td>{{ $t("staticni_normirani_trosak") }}</td>
        <td colspan="2" style="text-align: right">
          {{ format(staticni_normirani_trosak) }} RSD
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-size: 17px">
        <td>{{ $t("porez") }}</td>
        <td>20%</td>
        <td style="text-align: right">{{ format(porez) }} RSD</td>
      </tr>
      <tr style="font-size: 17px">
        <td>{{ $t("pio") }}</td>
        <td>24%</td>
        <td style="text-align: right">{{ format(pio) }} RSD</td>
      </tr>
      <tr style="font-size: 17px">
        <td>{{ $t("zdravstveno") }}</td>
        <td>{{ stopa }}%</td>
        <td style="text-align: right">{{ format(zdravstveno) }} RSD</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr
        style="background-color: #aca6c5; font-size: 17px; font-weight: initial"
      >
        <td>{{ $t("total_pid") }}</td>
        <td colspan="2" style="width: 100px; text-align: right">
          {{ format(total_pid) }} RSD
        </td>
      </tr>
      <tr
        style="background-color: #aca6c5; font-size: 17px; font-weight: initial"
      >
        <td>{{ $t("neto") }}</td>
        <td colspan="2" style="width: 100px; text-align: right">
          {{ format(neto) }} RSD
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style="font-size: 16px">
        <td>%</td>
        <td></td>
        <td>{{ format(procenat) }}%</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "ModelFirst",

  data() {
    return {
      selected: false,
      osnovica_za_pio: 0,
      osnovica_za_zdravstveno: 0,
      osnovica_porez: 0,
      normirani_trosak: 0,
      staticni_normirani_trosak: 103296,
      porez: 0,
      poreski_kredit_koji_se_ne_priznaje: 0,
      porez_ukupno: 0,
      pio: 0,
      zdravstveno: 0,
      total_pid: 0,
      neto: 0,
      procenat: "",
      stopa: 0.0,
      minimanlna_osnovica: 53370,
    };
  },
  methods: {
    change(osiguranje) {
      this.zdrav_stopa = osiguranje;
      if (!osiguranje) {
        this.stopa = 10.3;
      } else {
        this.stopa = 0.0;
      }
    },
    format(num) {
      num = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num);
      return num;
    },
    calculateModel1(bruto1, bruto4, bruto3, bruto, model_sum, bruto2) {
      bruto1 = parseFloat(bruto1);
      bruto2 = parseFloat(bruto2);
      bruto4 = parseFloat(bruto4);
      bruto3 = parseFloat(bruto3);
      bruto = parseFloat(bruto);
      model_sum = parseFloat(model_sum);
      if (bruto1 + bruto4 < this.staticni_normirani_trosak) {
        this.osnovica_za_pio = 0;
      } else {
        this.osnovica_za_pio = parseFloat(
          (
            bruto1 +
            bruto4 -
            this.staticni_normirani_trosak -
            (bruto1 + bruto4) * this.normirani_trosak
          ).toFixed(2)
        );
      }
      if (bruto1 + bruto3 + bruto4 < this.staticni_normirani_trosak) {
        this.osnovica_za_zdravstveno = 0;
      } else {
        this.osnovica_za_zdravstveno = parseFloat(
          (
            bruto1 +
            bruto3 +
            bruto4 -
            this.staticni_normirani_trosak -
            (bruto1 + bruto3 + bruto4) * this.normirani_trosak
          ).toFixed(2)
        );
      }
      if (bruto > 0 && bruto2 == bruto) {
        this.stopa = 0.0;
      }
      if (bruto < this.staticni_normirani_trosak) {
        this.osnovica_porez = 0;
      } else {
        this.osnovica_porez = parseFloat(
          (
            bruto -
            this.staticni_normirani_trosak -
            bruto * this.normirani_trosak
          ).toFixed(2)
        );
      }
      this.porez = parseFloat((this.osnovica_porez * 0.2).toFixed(2));
      this.poreski_kredit_koji_se_ne_priznaje = parseFloat(
        model_sum.toFixed(2)
      );
      this.porez_ukupno = parseFloat(
        (this.porez - this.poreski_kredit_koji_se_ne_priznaje).toFixed(2)
      );
      this.pio = parseFloat((this.osnovica_za_pio * 0.24).toFixed(2));

      if (
        this.osnovica_za_zdravstveno * (this.stopa / 100) <
        this.minimanlna_osnovica * (this.stopa / 100)
      ) {
        this.zdravstveno = parseFloat(
          (this.minimanlna_osnovica * (this.stopa / 100)).toFixed(2)
        );
      } else {
        this.zdravstveno = parseFloat(
          (this.osnovica_za_zdravstveno * (this.stopa / 100)).toFixed(2)
        );
      }
      this.total_pid = parseFloat(
        (this.zdravstveno + this.pio + this.porez_ukupno).toFixed(2)
      );
      this.neto = bruto - parseFloat(this.total_pid.toFixed(2));

      if (bruto !== 0) {
        this.procenat = parseFloat(((this.total_pid / bruto) * 100).toFixed(2));
      }
      if (bruto == 0) {
        this.procenat = 0;
      }
    },
  },
};
</script>

<style scoped>
table {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  border-radius: 20px;
  width: 100%;
  line-height: 15px;
}

td,
th {
  text-align: left;
  padding: 8px;
}
.model_1 {
  text-align: center;
  font-size: 20px;
  border: solid;
  border-color: transparent;
  background: #746c98;
  margin: auto;
  margin-bottom: 13px;
  color: white;
}
</style>
