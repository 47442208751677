import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "sr",
  messages: {
    sr: {
      naslov: "Na koji način ste ostvarili prihod koji želite da obračunate?",
      podnaslov:
        "Nakon što izaberete neku od opcija, pojaviće se polje za unos prihoda",
      rezident1:
        "Kao rezident na teritoriji Srbije od domaćeg fizičkog lica  ili inostranog klijenta koji nema predstavništvo u Srbiji.",
      rezident2:
        "Kao rezident Srbije, ali sam prihod ostvario/la na teritoriji druge države sa kojom imamo zaključen Ugovor o socijalnom osiguranju.",
      rezident3:
        "Kao rezident Srbije, ali sam prihod ostvario/la na teritoriji druge države sa kojom nemamo zaključen ugovor o socijalnom osiguranju.",
      rezident4:
        "Kao nerezident Srbije, ali sam prihod ostvario/la na teritoriji države Srbije.",
      unesi_prihod: "(Upišite prihod ostvaren u RSD u kvartalu po ovom osnovu)",
      osigurani:
        "Da li ste zdravstveno osigurani po nekom drugom osnovu (npr. kao zaposleno lice)?",
      da: "Da",
      ne: "Ne",
      ukupno_u_kvartalu: "Ukupan prihod ostvaren u kvartalu:",
      potvrdi: "Potvrdi",
      normirani_trosak: "NORMIRANI TROŠAK",
      staticni_normirani_trosak: "STATIČNI NORMIRANI TROŠAK",
      porez: "POREZ",
      pio: "PIO",
      zdravstveno: "ZDRAVSTVENO",
      total_pid: "UKUPNI POREZI I DOPRINOSI",
      neto: "NETO ZARADA",
      error1:
        "Nemoguće je u isto vreme imati svojstvo rezidenta i nerezidenta.",
      error2:
        "Prihod na koji je plaćen porez u drugoj zemlji ne može biti veći od ukupno ostvarenog prihoda u kvartalu po izabranom osnovu.",
      error3:
        "Iznos poreza ne može biti veći od ostvarenog prihoda na koji je plaćen porez u drugoj zemlji.",
    },
    en: {
      naslov: "How did you earn the income you want to calculate?",
      podnaslov:
        "After you select one of the options, a field to enter your income will appear",
      rezident1:
        "As a resident of Serbia, on the territory of Serbia, from a domestic natural person or a foreign client who does not have a representative office in Serbia.",
      rezident2:
        "As a resident of Serbia, but I earned my income on the territory of another country with which Serbia has concluded a Social Security Agreement.",
      rezident3:
        "As a resident of Serbia, but I earned my income in the territory of another country with which Serbia does not have a social security agreement.",
      rezident4:
        "As a non-resident of Serbia, but I earned my income on the territory of the Serbia.",
      unesi_prihod: "(Enter the income in RSD in the quarter earn on this way)",
      osigurani:
        "Do you have health insurance through another source (e.g. as an employee)?",
      da: "Yes",
      ne: "No",
      ukupno_u_kvartalu: "Total income earned in the quarter:",
      potvrdi: "Confirm",
      normirani_trosak: "NORM-SET COSTS",
      staticni_normirani_trosak: "STATIC NORM-SET COSTS",
      porez: "TAX",
      pio: "PENSION CONTRIBUTION",
      zdravstveno: "HEALTH CONTRIBUTION",
      total_pid: "TOTAL TAX AND CONTRIBUTIONS",
      neto: "NET",
      error1:
        "It is impossible to have the property of resident and non-resident at the same time.",
      error2:
        "Income on which tax has been paid in another country cannot be greater than the total income realized in the quarter on the selected basis.",
      error3:
        "The amount of tax cannot be higher than the realized income on which tax has been paid in another country.",
    },
  },
});

export default i18n;
