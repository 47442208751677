<template>
        <div>
            <p style="padding-top: 1px; margin-bottom: 5px; font-weight: bold; font-size: 22px;">Izaberi jezik (Choose language)</p> 
            <div class="wrapper5">
                <input class="inline-button" type="radio" id="sr" v-bind:value="'sr'" v-model="$i18n.locale">
                <label for="sr" style="padding-top: 3px; margin-right: 15px;">
                    <img 
                        src="~@/assets/srb.png" 
                        alt="frilens" 
                        style="width:18px;height:14px;">
                    SR
                </label>
                <input class="inline-button" type="radio" id="en" v-bind:value="'en'" v-model="$i18n.locale">
                <label for="en" style="padding-top: 3px;">
                    <img 
                    src="~@/assets/sad.png" 
                    alt="frilens" 
                    style="width:18px;height:14px;">
                    EN
                </label> 
            </div> 
        </div>
    </template>
      
    <script>
    import i18n from './i18n'

    export default {
    name: 'SelectLocale',
    data() {
        return { langs: ['sr', 'en'] }
    },
    components: {
    i18n
    }
    }
    
    </script>
    <style scoped>
    
    select {
        background-color: transparent;
        border: none;
        width: 80px;
        line-height: 20px;
        height: 20px;
        font-size: 14px;
        outline: 0;
        margin-bottom: 5px;
        }
    .languages{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .inline-button {
    display:inline-flex;
    margin-top: 0px;
    font-size: 19px;
    }
    .wrapper5 {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 11px;
    font-size: 19px;
}
    </style>